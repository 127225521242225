<template>
  <v-dialog v-model="localDialog" max-width="180vh" :fullscreen="$vuetify.breakpoint.xsOnly" persistent>
    <v-card height="82vh" >
      <v-card-title class="card-header-add font-weight-bold px-2 py-2 mb-5">
        <span class="font-size14">{{$t('laboratory-details')}} : {{patientDetails.userData.firstName + ' ' + patientDetails.userData.lastName}}</span>
        <v-spacer/>
        <v-icon color="white" @click="closeDeleteDialog">mdi-window-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-expansion-panels v-model="closeExpansionPanel">
              <v-expansion-panel
                  v-for="(mainCategory,i) in labDetails.data"
                  :key="i"
              >
                <v-expansion-panel-header class="font-size15 font-weight-medium pa-3 chips white--text">{{ mainCategory.name }}</v-expansion-panel-header>
                <v-expansion-panel-content   v-for="(subCategory,i) in mainCategory" :key="i">
                  <div class="pointer text-left" @click="openSubCategoryDetails(subCategory)" :class="{'font-weight-bold': labResults === subCategory}">{{ subCategory.name }}</div>
                  <v-divider v-show="i !== 'name'"></v-divider>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols="9">
            <v-card height="70vh">
              <v-card-title class="chips white--text py-1">
                <div v-if="Object.keys(labResults).length" class="font-size14" >
                  {{labResults.name}}
                </div>
                <div v-else class="text-center font-size14">
                  {{$t('select-subcategory')}}
                </div>
              </v-card-title>
              <v-card-text class="text-left font-weight-bold overflow-auto" style="height:65vh;">
                <v-card
                    v-for="(labResult, name) in labResults"
                    :key="name"
                    v-show="name !== 'name'"
                >
                  <v-card-text class="my-2">
                    <v-row>
                      <v-text-field label="Name" v-model="labResult.name" disabled dense hide-details outlined></v-text-field>
                    </v-row>
                    <v-row>
                      <v-col cols="8">
                        <v-text-field v-model="labResult.value" disabled dense hide-details label="Value" class="custom-disabled-text"></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field v-model="labResult.normal_range" disabled dense hide-details label="Reference Value" class="custom-disabled-text"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-textarea v-model="labResult.description" disabled dense hide-details outlined no-resize label="Description" height="120"></v-textarea>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: ['dialog', 'labDetails', 'patientDetails'],
  data () {
    return {
      localDialog: this.dialog,
      labResults: {},
      closeExpansionPanel: null,
    };
  },
  watch: {
    dialog (val) {
      this.localDialog = val;
    },
    localDialog (val) {
      this.$emit('update:dialog', val);
    },
  },
  methods: {
    openSubCategoryDetails (labResults) {
      this.labResults = labResults;
    },
    closeDeleteDialog () {
      this.localDialog = false;
      this.labResults = {};
      this.closeExpansionPanel = undefined;
    },
  },
};
</script>

<style>
.mdi-chevron-down::before{
  color:white !important;
}
.custom-disabled-text input {
  color: black !important;
  font-weight: normal !important;
}
</style>
