<template>
  <div class="mx-n2">
    <v-data-table
        :loading="loading"
        :search="search"
        ref="table"
        class="elevation-3 border-radius7 pa-2 text-xs-center"
        :items="labsData"
        :height="pageHeight(88)"
        dense
        :headers="LabsHeaders"
        :no-data-text="$t('no-data-available')"
        :footer-props= "getDataTableFooterProps('NotesTable')"
        :items-per-page="-1"
    >
      <template v-slot:top>
        <div>
          <v-row dense>
            <v-col cols="4">
              <v-text-field
                  append-icon="mdi-magnify"
                  :label="$t('search')"
                  dense
                  class="search-field my-2 mx-2"
                  hide-details
                  outlined
                  v-model="search"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:item="{ item }">
        <tr  @click="openLabResultsDialog(item)">
          <td class="table-font-size pl-4">
              {{ item.name }}
          </td>
          <td class="table-font-size px-0">
              {{ item.date }}
          </td>
          <td class="table-font-size px-0">
              <v-icon size="20" class="float-right mr-3" @click.stop="openLabResultsDialog(item)" color="blue">mdi-information-outline</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
      <LabDialog
          v-if="!loading"
          :dialog="dialog"
          @update:dialog="dialog = $event"
          :labDetails="labDetails"
          :patientDetails="detailedUser"
      >
      </LabDialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { getDataTableFooterProps } from '@/utils/utilities';
import LabDialog from '@/views/dialogs/LabDialog.vue';
export default {
  components: {
    LabDialog,
  },
  data () {
    return {
      dialog: false,
      labDetails: {},
      labsData: [
        {
          name: 'Lab 1',
          date: '2025-06-02',
          otherProperty: 'other',
          data: {
            BloodTests: {
              name: 'Blood Tests',
              CompleteBloodCount: {
                name: '1.A. Complete Blood Count (CBC)',
                Hemoglobin: {
                  name: 'Hemoglobin (Hb)',
                  value: '',
                  normal_range: '13.5-17.5 g/dL (M), 12-15.5 g/dL (F)',
                  description: 'Hemoglobin is a protein in red blood cells responsible for transporting oxygen from the lungs to tissues and returning carbon dioxide to the lungs for exhalation. It also helps determine the blood’s ability to carry oxygen throughout the body. Low levels of hemoglobin can indicate anemia, while high levels can signal dehydration or other health issues.',
                },
                WhiteBloodCellCount: {
                  name: 'White Blood Cell (WBC) Count',
                  value: '',
                  normal_range: '4,000-11,000 cells/µL',
                  description: 'White blood cells are part of the body’s immune system and help fight off infections. The WBC count measures the number of these cells in a blood sample. A high WBC count can indicate infection, inflammation, or a blood disorder, while a low count can suggest a weakened immune system or bone marrow problems.',
                },
                RedBloodCellCount: {
                  name: 'Red Blood Cell (RBC) Count',
                  value: '',
                  normal_range: '4.7-6.1 million/µL (M), 4.2-5.4 million/µL (F)',
                  description: 'Red blood cells are responsible for transporting oxygen to the body’s tissues and returning carbon dioxide to the lungs. The RBC count measures the number of these cells in a given volume of blood. A low RBC count may indicate anemia, while a high RBC count may suggest dehydration or other conditions that cause the body to produce too many red blood cells.',
                },
                Hematocrit: {
                  name: 'Hematocrit (Hct)',
                  value: '',
                  normal_range: '38.8-50.0% (M), 34.9-44.5% (F)',
                  description: 'Hematocrit is the percentage of the blood volume that is made up of red blood cells. It helps assess the blood\'s ability to carry oxygen. A low hematocrit may suggest anemia or blood loss, while a high hematocrit can be a sign of dehydration or a disorder that causes an overproduction of red blood cells.',
                },
                MeanCorpuscularVolume: {
                  name: 'Mean Corpuscular Volume (MCV)',
                  value: '',
                  normal_range: '80-100 fL',
                  description: 'MCV measures the average size of red blood cells. It helps to classify types of anemia. A low MCV (microcytic anemia) can indicate iron deficiency, while a high MCV (macrocytic anemia) can suggest vitamin B12 or folate deficiency.',
                },
                MeanCorpuscularHemoglobin: {
                  name: 'Mean Corpuscular Hemoglobin (MCH)',
                  value: '',
                  normal_range: '27-33 pg',
                  description: 'MCH measures the average amount of hemoglobin in each red blood cell. It helps assess the oxygen-carrying capacity of the blood. Low MCH levels may indicate iron deficiency anemia, while high MCH levels could suggest macrocytic anemia, often caused by vitamin B12 or folate deficiencies.',
                },
                MeanCorpuscularHemoglobinConcentration: {
                  name: 'Mean Corpuscular Hemoglobin Concentration',
                  value: '',
                  normal_range: '32-36 g/dL',
                  description: 'MCHC calculates the average concentration of hemoglobin in a given volume of red blood cells. It provides information about the color of red blood cells. Low MCHC (hypochromic) can be a sign of iron deficiency anemia, while high MCHC (hyperchromic) could indicate hereditary spherocytosis or other conditions that affect red blood cell shape.',
                },
                PlateletCount: {
                  name: 'Platelet Count',
                  value: '',
                  normal_range: '150,000-450,000 cells/µL',
                  description: 'Platelets are small cell fragments involved in blood clotting. A platelet count measures the number of platelets in a blood sample. A low platelet count (thrombocytopenia) can lead to excessive bleeding, while a high platelet count (thrombocytosis) may increase the risk of blood clotting disorders or other conditions like inflammation.',
                },
                RedCellDistributionWidth: {
                  name: 'Red Cell Distribution Width (RDW)',
                  value: '',
                  normal_range: '11.5-14.5%',
                  description: 'RDW measures the variation in size of red blood cells. A high RDW value suggests that there is a mix of both large and small red blood cells, which can be indicative of conditions like anemia, vitamin deficiencies, or other disorders. A normal RDW typically suggests a more uniform red blood cell population.',
                },
              },
              KidneyFunctionTest: {
                name: '1.B. Kidney Function Test (KFT)',
                SerumCreatinine: {
                  name: 'Serum Creatinine',
                  value: '',
                  normal_range: '0.6-1.3 mg/dL',
                  description: 'Serum creatinine is a waste product produced by muscles from the breakdown of creatine. It is filtered by the kidneys and excreted in urine. A high serum creatinine level can indicate impaired kidney function, as the kidneys may not be filtering creatinine effectively. It is often used to assess kidney function and monitor for kidney disease.',
                },
                BloodUrea: {
                  name: 'Blood Urea',
                  value: '',
                  normal_range: '7-20 mg/dL',
                  description: 'Blood urea is a waste product formed in the liver when the body breaks down proteins. It is carried by the bloodstream to the kidneys, where it is filtered and excreted in urine. Elevated blood urea levels can indicate kidney dysfunction, dehydration, or conditions that affect protein metabolism, such as a high-protein diet or gastrointestinal bleeding.',
                },
                BloodUreaNitrogen: {
                  name: 'Blood Urea Nitrogen (BUN)',
                  value: '',
                  normal_range: '6-24 mg/dL',
                  description: 'BUN measures the amount of nitrogen in the blood that comes from urea, a waste product of protein breakdown. Like blood urea, an increased BUN level may indicate kidney disease, dehydration, or excessive protein intake. Low BUN levels may be seen in liver disease or malnutrition.',
                },
                UricAcid: {
                  name: 'Uric Acid',
                  value: '',
                  normal_range: '3.5-7.2 mg/dL',
                  description: 'Uric acid is a waste product formed from the breakdown of purines, substances found in certain foods and beverages. It is normally excreted by the kidneys through urine. Elevated uric acid levels can lead to conditions like gout (a form of arthritis caused by uric acid crystals in the joints) or kidney stones. High levels may also be associated with metabolic disorders, kidney disease, or dehydration.',
                },
                GlomerularFiltrationRate: {
                  name: 'Glomerular Filtration Rate (GFR)',
                  value: '',
                  normal_range: '>60 mL/min',
                  description: 'GFR is a test that measures how well the kidneys are filtering blood. It calculates the rate at which blood is filtered by the glomeruli (tiny filtering units in the kidneys). A low GFR indicates impaired kidney function, and it is used to diagnose and stage chronic kidney disease (CKD). A normal GFR is typically above 90 mL/min/1.73m², with lower levels suggesting worsening kidney function.',
                },
              },
              LiverFunctionTest: {
                name: '1.C. Liver Function Test (LFT)',
                TotalBilirubin: {
                  name: 'Total Bilirubin',
                  value: '',
                  normal_range: '0.1-1.2 mg/dL',
                  description: 'Total bilirubin is the sum of direct (conjugated) and indirect (unconjugated) bilirubin in the blood. Bilirubin is a yellow compound that is produced when red blood cells break down. It is processed by the liver, and elevated levels can indicate liver disease, bile duct obstruction, or hemolysis (destruction of red blood cells). High levels can lead to jaundice (yellowing of the skin and eyes).',
                },
                DirectBilirubin: {
                  name: 'Direct Bilirubin',
                  value: '',
                  normal_range: '0-0.3 mg/dL',
                  description: 'Direct bilirubin (or conjugated bilirubin) is the form of bilirubin that has been processed by the liver. It is water-soluble and is excreted into the bile, ultimately being eliminated from the body through the intestines. Elevated direct bilirubin levels may indicate liver diseases such as hepatitis or cirrhosis, or bile duct obstruction (e.g., gallstones).',
                },
                IndirectBilirubin: {
                  name: 'Indirect Bilirubin',
                  value: '',
                  normal_range: '0.2-0.9 mg/dL',
                  description: 'Indirect bilirubin (or unconjugated bilirubin) is the form of bilirubin before it has been processed by the liver. It is not water-soluble and is carried in the bloodstream to the liver. High levels of indirect bilirubin can suggest problems with red blood cell breakdown, such as hemolytic anemia, or liver conditions that prevent the conjugation process.',
                },
                AspartateAminotransferase: {
                  name: 'Aspartate Aminotransferase (AST/SGOT)',
                  value: '',
                  normal_range: '5-40 U/L',
                  description: 'AST is an enzyme found in various tissues, including the liver, heart, muscles, and kidneys. It is released into the blood when these tissues are damaged. Elevated AST levels can indicate liver damage (e.g., hepatitis or cirrhosis), heart attack, muscle injury, or other organ damage. AST is commonly measured alongside ALT (alanine aminotransferase) to evaluate liver function.',
                },
                AlanineAminotransferase: {
                  name: 'Alanine Aminotransferase (ALT/SGPT)',
                  value: '',
                  normal_range: '7-56 U/L',
                  description: 'ALT is an enzyme primarily found in the liver. It plays a key role in metabolizing proteins. When liver cells are damaged, ALT is released into the bloodstream. High ALT levels are a strong indicator of liver damage, such as from hepatitis, fatty liver disease, or alcohol abuse. ALT is considered a more specific marker of liver injury than AST.',
                },
                AlkalinePhosphatase: {
                  name: 'Alkaline Phosphatase (ALP)',
                  value: '',
                  normal_range: '44-147 U/L',
                  description: 'ALP is an enzyme found in several tissues, including the liver, bones, kidneys, and bile ducts. Elevated ALP levels can indicate liver or bile duct problems (e.g., cholestasis or obstruction), bone disorders (e.g., Paget’s disease or bone tumors), or other conditions affecting these organs. It is often measured alongside other liver enzymes to determine the source of elevation.',
                },
                GammaGlutamylTransferase: {
                  name: 'Gamma-Glutamyl Transferase (GGT)',
                  value: '',
                  normal_range: '9-48 U/L',
                  description: 'GGT is an enzyme found primarily in the liver, bile ducts, and kidneys. It plays a role in the metabolism of glutathione and the transport of amino acids. High levels of GGT can indicate liver disease, bile duct obstruction, or excessive alcohol consumption. It is also used to assess the effect of alcohol use or monitor liver conditions.',
                },
                TotalProtein: {
                  name: 'Total Protein',
                  value: '',
                  normal_range: '6.0-8.3 g/dL',
                  description: 'Total protein measures the combined amount of two major types of proteins in the blood: albumin and globulin. Albumin helps maintain blood volume and pressure, while globulins are involved in immune functions and the transport of substances in the blood. Abnormal total protein levels can suggest conditions such as liver disease, kidney disease, malnutrition, or immune disorders. Low total protein levels may indicate liver or kidney disease, while high levels can be seen in chronic inflammatory conditions.',
                },
              },
              LipidProfile: {
                name: '1.D. Lipid Profile',
                TotalCholesterol: {
                  name: 'Total Cholesterol',
                  value: '',
                  normal_range: '<200 mg/dL',
                  description: 'Total cholesterol measures the overall amount of cholesterol in the blood, which includes low-density lipoprotein (LDL), high-density lipoprotein (HDL), and very low-density lipoprotein (VLDL). Cholesterol is a fatty substance essential for cell membranes, but high levels, especially of LDL, can increase the risk of cardiovascular diseases. A healthy total cholesterol level is usually less than 200 mg/dL, with higher levels suggesting an increased risk for heart disease.',
                },
                HighDensityLipoprotein: {
                  name: 'High-Density Lipoprotein (HDL)',
                  value: '',
                  normal_range: '>40 mg/dL (M), >50 mg/dL (F)',
                  description: 'HDL is often referred to as \'good\' cholesterol. It helps remove excess cholesterol from the bloodstream and transports it to the liver for elimination. High levels of HDL are considered protective against heart disease because they help clear cholesterol from arteries, reducing plaque buildup. Ideal HDL levels are generally higher than 40 mg/dL for men and 50 mg/dL for women.',
                },
                LowDensityLipoprotein: {
                  name: 'Low-Density Lipoprotein (LDL)',
                  value: '',
                  normal_range: '<100 mg/dL',
                  description: 'LDL is known as \'bad\' cholesterol. It carries cholesterol from the liver to the cells. High levels of LDL cholesterol can lead to the buildup of plaque in the arteries, which can increase the risk of atherosclerosis (narrowing and hardening of the arteries), heart attack, and stroke. Lower LDL levels are preferable, with an optimal level typically being below 100 mg/dL.',
                },
                'VeryLowDensityLipoprotein (VLDL)': {
                  name: 'Very Low-Density Lipoprotein (VLDL)',
                  value: '',
                  normal_range: '2-30 mg/dL',
                  description: 'VLDL is another type of \'bad\' cholesterol. It carries triglycerides in the bloodstream and is considered the least healthy type of lipoprotein. High VLDL levels are associated with an increased risk of cardiovascular diseases, similar to LDL. Elevated VLDL levels can indicate metabolic disorders, obesity, or high triglyceride levels. VLDL levels are typically estimated as a percentage of triglycerides, with the ideal level being low.',
                },
                Triglycerides: {
                  name: 'Triglycerides',
                  value: '',
                  normal_range: '<150 mg/dL',
                  description: 'Triglycerides are a type of fat (lipid) found in the blood. They are used by the body for energy, but when consumed in excess, they are stored as fat. High triglyceride levels can increase the risk of heart disease and are often associated with conditions such as obesity, diabetes, and metabolic syndrome. Normal triglyceride levels are typically less than 150 mg/dL. High triglycerides can also be caused by excessive alcohol consumption or a diet high in refined carbohydrates and sugars.',
                },
              },
              BloodSugarTests: {
                name: '1.E. Blood Sugar Tests',
                FastingBloodSugar: {
                  name: 'Fasting Blood Sugar (FBS)',
                  value: '',
                  normal_range: '70-99 mg/dL',
                  description: 'Fasting blood sugar is the measurement of blood glucose levels after an overnight fast (usually 8-12 hours without food). It provides insight into how the body regulates glucose in the absence of food. Elevated FBS levels can be indicative of insulin resistance, prediabetes, or diabetes. A normal FBS level is typically between 70-100 mg/dL, with levels higher than 126 mg/dL suggesting diabetes.',
                },
                PostprandialBloodSugar: {
                  name: 'Postprandial Blood Sugar (PPBS)',
                  value: '',
                  normal_range: '<140 mg/dL',
                  description: 'Postprandial blood sugar refers to the blood glucose level measured 2 hours after eating a meal. It helps assess how effectively the body processes glucose after food consumption. Elevated postprandial blood sugar levels may indicate impaired glucose tolerance or diabetes. A normal PPBS level is generally below 140 mg/dL, while levels above 200 mg/dL suggest diabetes.',
                },
                RandomBloodSugar: {
                  name: 'Random Blood Sugar (RBS)',
                  value: '',
                  normal_range: '<200 mg/dL',
                  description: 'Random blood sugar is a test that measures blood glucose levels at any time of the day, regardless of when the last meal was consumed. It provides a snapshot of the body’s blood sugar levels. While RBS levels can fluctuate throughout the day, consistently high levels may indicate diabetes or other metabolic disorders. A random blood sugar level higher than 200 mg/dL may signal diabetes, especially if accompanied by symptoms like frequent urination and excessive thirst.',
                },
                GlycatedHemoglobin_HbA1c: {
                  name: 'Glycated Hemoglobin (HbA1c)',
                  value: '',
                  normal_range: '<5.7%',
                  description: 'HbA1c is a blood test that measures the average level of blood glucose over the past 2-3 months by assessing the percentage of hemoglobin (a protein in red blood cells) that has glucose attached to it. It is a key indicator of long-term blood sugar control. The higher the HbA1c level, the poorer the blood sugar control. Normal HbA1c levels are typically below 5.7%. A level between 5.7% and 6.4% indicates prediabetes, while levels 6.5% or higher are diagnostic for diabetes.',
                },
              },
              ThyroidFunctionTest: {
                name: '1.F. Thyroid Function Test (TFT)',
                TSH: {
                  name: 'Thyroid-Stimulating Hormone (TSH)',
                  value: '',
                  normal_range: '0.4-4.0 mIU/L',
                  description: 'TSH is a hormone produced by the pituitary gland that stimulates the thyroid gland to produce thyroid hormones (T3 and T4). TSH levels are typically measured to assess thyroid function. High levels of TSH may indicate hypothyroidism (an underactive thyroid), while low levels may suggest hyperthyroidism (an overactive thyroid). Normal TSH levels typically range from 0.4 to 4.0 mIU/L, but the optimal level can vary depending on individual health conditions.',
                },
                FreeT3: {
                  name: 'Free T3 (Triiodothyronine)',
                  value: '',
                  normal_range: '2.3-4.2 pg/mL',
                  description: 'Free T3 is the active form of the thyroid hormone triiodothyronine, which plays a crucial role in regulating metabolism, heart rate, and body temperature. Free T3 is the unbound form of the hormone and is more readily available to the body. Low levels of free T3 may indicate hypothyroidism, while high levels may suggest hyperthyroidism. Normal levels are typically between 2.3 and 4.2 pg/mL, though this can vary depending on the laboratory.',
                },
                FreeT4: {
                  name: 'Free T4 (Thyroxine)',
                  value: '',
                  normal_range: '0.8-1.8 ng/dL',
                  description: 'Free T4 is the unbound form of thyroxine, a thyroid hormone responsible for regulating various metabolic processes. T4 is converted to the more active T3 in the body. Low levels of free T4 are typically associated with hypothyroidism, while high levels may indicate hyperthyroidism. Normal levels of free T4 are usually between 0.8 and 1.8 ng/dL, depending on the lab and the individual’s health conditions.',
                },
                TotalT3: {
                  name: 'Total T3',
                  value: '',
                  normal_range: '80-200 ng/dL',
                  description: 'Total T3 measures the total amount of triiodothyronine hormone in the blood, both bound and unbound (free). It is less commonly used than free T3, as it can be influenced by factors like protein levels in the blood. Elevated total T3 levels may indicate hyperthyroidism, while low levels can be a sign of hypothyroidism or other thyroid dysfunctions. Normal levels are generally between 80-200 ng/dL, depending on the laboratory.',
                },
                TotalT4: {
                  name: 'Total T4',
                  value: '',
                  normal_range: '5-12 µg/dL',
                  description: 'Total T4 measures the total amount of thyroxine hormone in the blood, both bound to proteins and free. Like total T3, total T4 levels are used to evaluate thyroid function, but it can be less specific than free T4. High total T4 levels may suggest hyperthyroidism, while low levels can indicate hypothyroidism. Normal total T4 levels are typically between 5.0 and 12.0 µg/dL.',
                },
                'AntiThyroidPeroxidaseAntibodies)': {
                  name: 'Anti-TPO (Thyroid Peroxidase Antibodies)',
                  value: '',
                  normal_range: '<35 IU/mL',
                  description: 'Anti-TPO antibodies are immune system proteins that attack thyroid peroxidase, an enzyme in the thyroid gland essential for producing thyroid hormones. Elevated levels of Anti-TPO antibodies are typically found in autoimmune thyroid conditions like Hashimoto\'s thyroiditis (hypothyroidism) and Graves\' disease (hyperthyroidism). High levels of Anti-TPO may indicate an autoimmune thyroid disorder, even if thyroid function tests (such as TSH, T3, and T4) are normal.',
                },
              },
              Electrolytes: {
                name: '1.G. Electrolytes',
                Sodium: {
                  name: 'Sodium (Na⁺)',
                  value: '',
                  normal_range: '135-145 mEq/L',
                  description: 'Sodium is an essential electrolyte that helps regulate fluid balance, blood pressure, and nerve and muscle function. It is primarily found in extracellular fluid and helps maintain osmotic pressure. Low sodium levels (hyponatremia) can lead to symptoms like confusion, seizures, and muscle weakness, while high levels (hypernatremia) may cause dehydration, high blood pressure, and kidney problems. Normal sodium levels range from 135 to 145 mEq/L.',
                },
                Potassium: {
                  name: 'Potassium (K⁺)',
                  value: '',
                  normal_range: '3.5-5.1 mEq/L',
                  description: 'Potassium is a vital electrolyte that helps regulate heart function, muscle contractions, and nerve signals. It is primarily found inside cells (intracellular fluid) and plays a key role in maintaining the electrical activity of the heart. Low potassium levels (hypokalemia) can cause muscle weakness, cramps, and arrhythmias, while high potassium levels (hyperkalemia) can lead to dangerous heart rhythms. Normal potassium levels typically range from 3.5 to 5.0 mEq/L.',
                },
                Chloride: {
                  name: 'Chloride (Cl⁻)',
                  value: '',
                  normal_range: '96-106 mEq/L',
                  description: 'Chloride is an electrolyte that works with sodium to help maintain fluid balance, osmotic pressure, and acid-base balance. It is commonly found in extracellular fluid and helps regulate the pH of the body. Abnormal chloride levels can indicate issues with hydration or acid-base imbalances. Low chloride levels (hypochloremia) can be seen in conditions like vomiting or kidney disease, while high levels (hyperchloremia) may occur with dehydration or metabolic acidosis. Normal chloride levels typically range from 98 to 106 mEq/L.',
                },
                Calcium: {
                  name: 'Calcium (Ca²⁺)',
                  value: '',
                  normal_range: '8.5-10.2 mg/dL',
                  description: 'Calcium is a vital mineral that is important for bone and teeth health, muscle function, nerve transmission, and blood clotting. It is primarily stored in the bones and teeth but is also found in the blood. Abnormal calcium levels can indicate problems with the parathyroid gland, kidney disease, or bone health. Low calcium levels (hypocalcemia) can cause muscle cramps and tingling, while high levels (hypercalcemia) can lead to kidney stones, bone pain, and fatigue. Normal calcium levels typically range from 8.5 to 10.2 mg/dL.',
                },
                Magnesium: {
                  name: 'Magnesium (Mg²⁺)',
                  value: '',
                  normal_range: '1.7-2.2 mg/dL',
                  description: 'Magnesium is a mineral involved in over 300 biochemical reactions in the body, including muscle function, nerve function, and energy production. It helps regulate blood sugar levels and supports the immune system. Low magnesium levels (hypomagnesemia) can cause muscle cramps, tremors, and irregular heart rhythms, while high levels (hypermagnesemia) may lead to nausea, respiratory depression, or cardiac arrest. Normal magnesium levels typically range from 1.7 to 2.2 mg/dL.',
                },
                Phosphorus: {
                  name: 'Phosphorus (P)',
                  value: '',
                  normal_range: '2.5-4.5 mg/dL',
                  description: 'Phosphorus is a mineral that plays a key role in energy production, bone health, and the formation of DNA and RNA. It is mainly found in bones and teeth but also circulates in the blood in the form of phosphate. Abnormal phosphorus levels can indicate problems with kidney function, bone metabolism, or parathyroid hormone regulation. Low phosphorus levels (hypophosphatemia) can lead to muscle weakness, fatigue, and bone pain, while high levels (hyperphosphatemia) can be seen in kidney disease and lead to calcium-phosphate deposits in tissues. Normal phosphorus levels are typically between 2.5 and 4.5 mg/dL.',
                },
              },
              CoagulationProfile: {
                name: '1.H. Coagulation Profile',
                ProthrombinTime: {
                  name: 'Prothrombin Time (PT)',
                  value: '',
                  normal_range: '11-13.5 sec',
                  description: 'Prothrombin time (PT) is a blood test that measures the time it takes for blood to clot. It specifically evaluates the extrinsic and common pathways of the coagulation cascade, including factors I (fibrinogen), II (prothrombin), V, VII, and X. PT is often used to monitor patients on blood-thinning medications like warfarin or to assess the function of the liver, as the liver produces clotting factors. A prolonged PT may indicate a deficiency in clotting factors, liver disease, or a blood clotting disorder.',
                },
                InternationalNormalizedRatio: {
                  name: 'International Normalized Ratio (INR)',
                  value: '',
                  normal_range: '0.8-1.1',
                  description: 'The International Normalized Ratio (INR) is a standardized way to measure PT and is used to monitor blood clotting in patients on anticoagulant therapy (such as warfarin). It helps ensure that PT results are consistent across different laboratories. The INR compares a patient\'s PT to a normal or reference range, adjusting for variability in test methods. A normal INR is typically between 0.8 and 1.2, but for patients on warfarin therapy, the target range may be 2.0 to 3.0 to prevent clot formation.',
                },
                ActivatedPartialThromboplastinTime: {
                  name: 'Activated Partial Thromboplastin Time (aPTT)',
                  value: '',
                  normal_range: '25-35 sec',
                  description: 'The aPTT test measures the time it takes for blood to clot through the intrinsic and common pathways of the coagulation cascade, assessing factors like I (fibrinogen), II (prothrombin), V, VIII, IX, X, XI, and XII. It is commonly used to monitor patients on heparin therapy or to diagnose clotting disorders. A prolonged aPTT can indicate bleeding disorders, such as hemophilia, or the presence of inhibitors (antibodies that interfere with clotting). Normal aPTT ranges typically between 25 and 35 seconds.',
                },
                Fibrinogen: {
                  name: 'Fibrinogen',
                  value: '',
                  normal_range: '200-400 mg/dL',
                  description: 'Fibrinogen is a protein produced by the liver that is essential for blood clot formation. During the clotting process, fibrinogen is converted into fibrin, which forms a mesh that helps to stop bleeding. Low fibrinogen levels can indicate clotting disorders, liver disease, or excessive bleeding, while high levels can be associated with inflammation, infection, or an increased risk of thrombosis (blood clot formation). Normal fibrinogen levels are typically between 200 and 400 mg/dL, but they can vary depending on the laboratory and the patient\'s health status.',
                },
                Ddimer: {
                  name: 'D-dimer',
                  value: '',
                  normal_range: '< 0.5 µg/mL (or < 500 ng/mL D-dimer units)',
                  description: 'The D-dimer test measures the presence of fibrin degradation products in the blood, which indicates clot formation and breakdown. It is used to help diagnose or rule out conditions related to excessive clotting, such as deep vein thrombosis (DVT), pulmonary embolism (PE), and disseminated intravascular coagulation (DIC). Elevated D-dimer levels suggest increased clotting activity but are not diagnostic on their own, requiring further testing. Normal levels are typically less than 0.5 µg/mL (or 500 ng/mL D-dimer units), but values may vary depending on the laboratory and clinical context.',
                },
              },
              TumorMarkers: {
                name: '1.I. Tumor Markers',
                AlphaFetoprotein_LiverCancer: {
                  name: 'Alpha-Fetoprotein (AFP) – Liver Cancer',
                  value: '',
                  normal_range: '<10 ng/mL',
                  description: 'Alpha-fetoprotein (AFP) is a protein that is typically produced by the liver and yolk sac of a developing fetus. Elevated AFP levels in adults may indicate liver cancer (hepatocellular carcinoma), as well as other conditions like cirrhosis or liver damage. AFP is often used in conjunction with imaging tests to help diagnose liver cancer, monitor treatment response, or detect recurrence after treatment. Normal AFP levels in adults are generally low, but levels above 400 ng/mL can be indicative of liver cancer.',
                },
                CarcinoembryonicAntigen_ColonLungCancer: {
                  name: 'Carcinoembryonic Antigen (CEA) – Colon & Lung Cancer',
                  value: '',
                  normal_range: '<5 ng/mL',
                  description: 'Carcinoembryonic antigen (CEA) is a glycoprotein that is elevated in several types of cancers, most notably colon and lung cancers. It is used to monitor cancer progression, evaluate treatment effectiveness, and check for recurrence after treatment. While CEA is not a definitive diagnostic marker for cancer, higher-than-normal levels can indicate malignancy or other conditions like inflammatory bowel disease. Normal CEA levels are typically below 3 ng/mL for non-smokers and may be higher in smokers.',
                },
                CancerAntigen125_OvarianCancer: {
                  name: 'Cancer Antigen 125 (CA-125) – Ovarian Cancer',
                  value: '',
                  normal_range: '<35 U/mL',
                  description: 'Cancer antigen 125 (CA-125) is a protein found on the surface of ovarian cancer cells. Elevated CA-125 levels are often associated with ovarian cancer and are used to monitor treatment, assess response to therapy, and check for recurrence. While it is not specific to ovarian cancer (levels can also rise in other cancers or benign conditions), CA-125 is a useful tool in the diagnosis and management of ovarian cancer. Normal CA-125 levels are generally below 35 U/mL.',
                },
                CancerAntigen153_BreastCancer: {
                  name: 'Cancer Antigen 15-3 (CA 15-3) – Breast Cancer',
                  value: '',
                  normal_range: '<30 U/mL',
                  description: 'Cancer antigen 15-3 (CA 15-3) is a protein that is often elevated in breast cancer, particularly in advanced stages or metastasis. It is used to monitor treatment response, detect recurrence, and assess prognosis in patients with breast cancer. While it is not used as a screening test, elevated CA 15-3 levels can indicate breast cancer or other conditions, such as liver or lung disease. Normal CA 15-3 levels are typically below 30 U/mL.',
                },
                CancerAntigen199_PancreaticCancer: {
                  name: 'Cancer Antigen 19-9 (CA 19-9) – Pancreatic Cancer',
                  value: '',
                  normal_range: '<37 U/mL',
                  description: 'Cancer antigen 19-9 (CA 19-9) is a marker often elevated in pancreatic cancer. It can also be increased in cancers of the digestive system, including bile duct, colon, and gastric cancers. CA 19-9 is primarily used to monitor treatment response and detect recurrence in patients with pancreatic cancer. It is not used as a screening test, as other conditions such as gallstones or pancreatitis can also cause elevated levels. Normal CA 19-9 levels are generally below 37 U/mL.',
                },
                ProstateSpecificAntigen_ProstateCancer: {
                  name: 'Prostate-Specific Antigen (PSA) – Prostate Cancer',
                  value: '',
                  normal_range: '<4 ng/mL',
                  description: 'Prostate-specific antigen (PSA) is a protein produced by the prostate gland. Elevated PSA levels are most commonly associated with prostate cancer, though they can also be seen in benign prostate conditions like benign prostatic hyperplasia (BPH) or prostatitis. PSA testing is commonly used for prostate cancer screening, monitoring treatment, and detecting recurrence after surgery or radiation. A normal PSA level is generally below 4 ng/mL, though the cutoff may vary based on age and other factors.',
                },
                BetaHumanChorionicGonadotropin_TesticularCancer: {
                  name: 'Beta-Human Chorionic Gonadotropin (β-hCG) – Testicular Cancer',
                  value: '',
                  normal_range: '<5 mIU/mL',
                  description: 'Beta-human chorionic gonadotropin (β-hCG) is a hormone produced during pregnancy but can also be elevated in certain cancers, particularly testicular cancer. Elevated levels of β-hCG are often found in patients with germ cell tumors of the testes, such as seminomas and non-seminomas. It is used to monitor treatment, assess prognosis, and check for recurrence in testicular cancer. Normal β-hCG levels in men are generally undetectable, but elevated levels can indicate testicular cancer or other malignancies.',
                },
                LactateDehydrogenase_GeneralCancerMarker: {
                  name: 'Lactate Dehydrogenase (LDH) – General Cancer Marker',
                  value: '',
                  normal_range: '125 - 220 U/L',
                  description: 'Lactate dehydrogenase (LDH) is an enzyme found in nearly all body tissues and is involved in energy production. Elevated LDH levels can be a general marker of tissue damage and are often seen in various cancers, such as lymphoma, leukemia, and melanoma. While not specific to cancer, high LDH levels may indicate aggressive or advanced disease and are used to assess prognosis and monitor treatment response. Normal LDH levels vary by age and sex but are typically between 140 and 280 U/L.',
                },
                Thyroglobulin_ThyroidCancer: {
                  name: 'Thyroglobulin – Thyroid Cancer',
                  value: '',
                  normal_range: '<55 ng/mL',
                  description: 'Thyroglobulin is a protein produced by thyroid cells and is used as a marker for thyroid cancer, particularly after thyroid surgery or radioactive iodine treatment. Elevated levels of thyroglobulin can indicate the presence of remaining cancer cells or recurrence in patients who have had a thyroidectomy for thyroid cancer. It is most commonly used to monitor patients with differentiated thyroid cancer (papillary and follicular). Normal thyroglobulin levels are generally undetectable or very low in patients who have undergone thyroidectomy and are not on thyroid hormone replacement.',
                },
              },
              COVID19Tests: {
                name: '1.J. COVID-19 Tests',
                RT_PCR: {
                  name: 'RT-PCR (Real-Time Polymerase Chain Reaction)',
                  value: '',
                  normal_range: 'Negative',
                  description: 'RT-PCR is the \'gold standard\' test for detecting COVID-19 infection. It detects the genetic material (RNA) of the virus by amplifying the viral genome. A sample is typically collected using a nasopharyngeal swab, and the test provides highly accurate results, particularly in the early stages of infection. RT-PCR is widely used for diagnostic purposes, travel testing, and to confirm active infection. It is highly sensitive and specific, with a longer turnaround time compared to antigen tests.',
                },
                RapidAntigenTest: {
                  name: 'Rapid Antigen Test (RAT)',
                  value: '',
                  normal_range: 'Negative',
                  description: 'The rapid antigen test is a quicker, less expensive alternative to RT-PCR for detecting COVID-19. It detects viral proteins (antigens) from a swab sample, usually taken from the nose or throat. While RAT provides results in 15-30 minutes, it is less sensitive than RT-PCR, meaning it may produce false-negative results, especially in individuals with low viral loads or in the early stages of infection. However, it is useful for rapid screening, especially in large groups or in symptomatic individuals.',
                },
                COVID19AntibodyTestIgG: {
                  name: 'COVID-19 Antibody Test IgG',
                  value: '',
                  normal_range: 'Positive if previously infected',
                  description: 'The IgG antibody test detects antibodies produced by the immune system after exposure to the COVID-19 virus. IgG antibodies typically appear 1-2 weeks after infection and indicate a past infection or exposure to the virus. This test is not used for diagnosing active infections but is helpful for determining previous exposure to the virus. It can also be used in research studies or to assess the population\'s level of immunity after vaccination or infection.',
                },
                COVID19AntibodyTestIgM: {
                  name: 'COVID-19 Antibody Test IgM',
                  value: '',
                  normal_range: 'Recent infection if positive',
                  description: 'The IgM antibody test detects early-stage antibodies that are produced shortly after initial exposure to COVID-19. IgM antibodies generally appear 3-7 days after infection. This test can help identify recent infection and may be used in conjunction with other tests to diagnose acute or ongoing infections. However, the IgM response varies from person to person, and it is not always present in the early stages of illness.',
                },
                Interleukin6: {
                  name: 'Interleukin-6 (IL-6) (for severe cases)',
                  value: '',
                  normal_range: '<7 pg/mL',
                  description: 'Interleukin-6 (IL-6) is a cytokine, a type of signaling molecule involved in the immune response. Elevated IL-6 levels are commonly seen in severe COVID-19 cases and may indicate a hyperactive immune response or cytokine storm, which can contribute to organ damage, respiratory distress, and other severe symptoms. IL-6 testing is often used to assess the severity of COVID-19 and may guide treatment decisions, especially in patients who are at high risk of complications. Elevated IL-6 levels may prompt the use of targeted therapies, such as IL-6 inhibitors, to manage inflammation in severe cases.',
                },
              },
            },
            UrineTests: {
              name: 'Urine Tests',
              UrineRoutineAnalysis: {
                name: '2.A. Urine Routine Analysis',
                Color: {
                  name: 'Color',
                  value: '',
                  normal_range: 'Pale Yellow',
                  description: 'The color of urine can provide clues about hydration status, diet, and possible health issues. Normal urine color ranges from pale yellow to amber, depending on the concentration of urochrome, a pigment produced by the breakdown of hemoglobin. Dark yellow or amber urine may indicate dehydration, while pale or clear urine may indicate overhydration. Unusual colors, such as red, brown, or green, could indicate bleeding, liver disease, or certain medications.',
                },
                Appearance: {
                  name: 'Appearance',
                  value: '',
                  normal_range: 'Clear',
                  description: 'The appearance of urine is assessed for clarity and cloudiness. Normal urine is usually clear or slightly cloudy. Cloudy urine may indicate the presence of particles such as bacteria, white blood cells, mucus, or crystals. It could be a sign of infection, kidney stones, or other urinary tract issues. If urine is very cloudy, it may require further analysis to identify the cause.',
                },
                pH: {
                  name: 'pH',
                  value: '',
                  normal_range: '4.5-8.0',
                  description: 'The pH of urine indicates its acidity or alkalinity. Normal urine pH ranges from 4.5 to 8.0, with 6.0 being considered neutral. A more acidic pH (below 6.0) can be caused by high-protein diets, dehydration, or certain medications, while a more alkaline pH (above 7.0) may result from a vegetarian diet, urinary tract infections (UTIs), or kidney disease. Abnormal pH values can provide insight into the body’s metabolic state or potential issues with the urinary system.',
                },
                SpecificGravity: {
                  name: 'Specific Gravity',
                  value: '',
                  normal_range: '1.005-1.030',
                  description: 'Specific gravity measures the concentration of dissolved substances in urine, providing an indication of hydration status and kidney function. It ranges from 1.002 to 1.030, with higher values indicating concentrated urine (often due to dehydration) and lower values indicating dilute urine (which can be a result of excessive fluid intake or kidney disease). This test helps assess the kidneys\' ability to concentrate urine and maintain fluid balance.',
                },
                Protein: {
                  name: 'Protein',
                  value: '',
                  normal_range: 'Negative',
                  description: 'The presence of protein in urine (proteinuria) can be a sign of kidney damage or disease, as healthy kidneys typically prevent large amounts of protein from being excreted. Small amounts of protein may appear after exercise, during pregnancy, or in response to stress, but persistent proteinuria can indicate conditions like glomerulonephritis, diabetes, or hypertension. Normal urine should have little to no protein.',
                },
                Glucose: {
                  name: 'Glucose',
                  value: '',
                  normal_range: 'Negative',
                  description: 'Normally, urine contains no glucose (sugar). The presence of glucose in urine (glucosuria) can indicate uncontrolled diabetes mellitus, kidney problems, or other conditions where the kidneys cannot properly filter glucose. Glucose appears in the urine when blood glucose levels exceed the renal threshold, which is typically around 180 mg/dL. Persistent glucose in the urine should prompt further investigation for diabetes or metabolic disorders.',
                },
                Ketones: {
                  name: 'Ketones',
                  value: '',
                  normal_range: 'Negative',
                  description: 'Ketones are produced when the body breaks down fat for energy instead of carbohydrates. The presence of ketones in urine (ketonuria) can indicate that the body is in a state of ketosis, which can occur in uncontrolled diabetes (especially type 1), prolonged fasting, starvation, or low-carb diets. High ketone levels in urine can also suggest diabetic ketoacidosis (DKA), a serious condition requiring immediate medical attention.',
                },
                Bilirubin: {
                  name: 'Bilirubin',
                  value: '',
                  normal_range: 'Negative',
                  description: 'Bilirubin is a yellow compound produced during the breakdown of red blood cells. Its presence in urine (bilirubinuria) can indicate liver disease, such as hepatitis, cirrhosis, or bile duct obstruction. Normally, bilirubin is processed by the liver and excreted in bile, but when the liver is not functioning properly, bilirubin may leak into the bloodstream and be filtered by the kidneys into the urine. It can give an early indication of liver dysfunction.',
                },
                Urobilinogen: {
                  name: 'Urobilinogen',
                  value: '',
                  normal_range: '0.1-1.0 mg/dL',
                  description: 'Urobilinogen is a byproduct of bilirubin reduction that occurs in the intestines. Normally, small amounts of urobilinogen are present in urine, but elevated levels can indicate liver disease (e.g., hepatitis, cirrhosis) or hemolysis (destruction of red blood cells). Absent or very low levels of urobilinogen may indicate bile duct obstruction or other issues with the liver\'s ability to process bilirubin.',
                },
                Nitrites: {
                  name: 'Nitrites',
                  value: '',
                  normal_range: 'Negative',
                  description: 'Nitrites in urine can be a sign of a bacterial urinary tract infection (UTI). Certain bacteria, such as Escherichia coli (E. coli), can convert urinary nitrate to nitrite, and their presence in urine suggests bacterial activity in the urinary system. A positive nitrite test is often used as a diagnostic tool for UTIs, especially when combined with other symptoms and tests.',
                },
                LeukocyteEsterase: {
                  name: 'Leukocyte Esterase',
                  value: '',
                  normal_range: 'Negative',
                  description: 'Leukocyte esterase is an enzyme found in white blood cells (leukocytes). The presence of leukocyte esterase in urine indicates the presence of white blood cells, often due to infection or inflammation in the urinary tract. This test is commonly used in conjunction with the nitrite test to detect UTIs. A positive result suggests a UTI or other inflammatory conditions of the urinary system.',
                },
              },
              UrineMicroscopicExamination: {
                name: '2.B. Urine Microscopic Examination',
                RedBloodCells: {
                  name: 'Red Blood Cells (RBCs)',
                  value: '',
                  normal_range: '0-3 /HPF',
                  description: 'The presence of red blood cells (hematuria) in urine can indicate bleeding in the urinary tract. Normally, urine should have no or very few RBCs. The presence of RBCs may be due to various causes such as urinary tract infections (UTIs), kidney stones, trauma to the urinary tract, glomerulonephritis (inflammation of the kidney\'s filtering units), or tumors in the urinary system. A large number of RBCs may require further evaluation to determine the source of bleeding.',
                },
                WhiteBloodCells: {
                  name: 'White Blood Cells (WBCs)',
                  value: '',
                  normal_range: '0-5 /HPF',
                  description: 'White blood cells (leukocytes) in urine can indicate an infection or inflammation in the urinary tract. Normally, urine has only a few WBCs, if any. Elevated WBC counts, especially when associated with symptoms like pain or burning during urination, can suggest a urinary tract infection (UTI), pyelonephritis (kidney infection), or other inflammatory conditions such as interstitial cystitis or glomerulonephritis. WBCs are often detected through a leukocyte esterase test as well.',
                },
                EpithelialCells: {
                  name: 'Epithelial Cells',
                  value: '',
                  normal_range: 'Few',
                  description: 'Epithelial cells are the cells that line the urinary tract. A small number of epithelial cells may be present in the urine normally, especially from the urethra or vagina. However, an increased number of epithelial cells can indicate a urinary tract infection, kidney disease, or contamination from the external genital area. Squamous epithelial cells are often seen in the urine and may be a sign of contamination if found in high numbers, while renal tubular epithelial cells suggest kidney pathology, such as tubular damage.',
                },
                Casts: {
                  name: 'Casts (Hyaline, Granular, RBC, WBC, etc.)',
                  value: '',
                  normal_range: 'None',
                  description: 'Casts are cylindrical structures formed in the kidney tubules and can be seen under the microscope. They are made up of proteins and cells that get trapped in the tubules as urine flows through the kidneys. The presence and type of casts can help diagnose specific kidney conditions: - Hyaline casts: Composed of just protein, these are often seen in normal urine or in cases of dehydration or mild kidney stress. - Granular casts: Indicative of kidney damage, granular casts often suggest acute tubular necrosis or glomerulonephritis. - Red blood cell (RBC) casts: Associated with glomerulonephritis or other conditions that cause bleeding within the kidneys. - White blood cell (WBC) casts: Indicative of infection or inflammation in the kidneys, such as in pyelonephritis or interstitial nephritis.',
                },
                Crystals: {
                  name: 'Crystals',
                  value: '',
                  normal_range: 'Occasional',
                  description: 'Crystals in urine can form when certain substances become oversaturated and precipitate out of the urine. The type of crystal can provide valuable information about the underlying condition: - Calcium oxalate crystals: Commonly associated with kidney stones. - Uric acid crystals: May indicate gout, dehydration, or metabolic disorders. - Struvite crystals: Often linked to urinary tract infections caused by urease-producing bacteria. - Cystine crystals: Can be associated with a rare genetic disorder known as cystinuria, which leads to kidney stone formation.',
                },
                Bacteria: {
                  name: 'Bacteria',
                  value: '',
                  normal_range: 'Absent',
                  description: 'The presence of bacteria in urine (bacteriuria) is a strong indicator of a urinary tract infection (UTI). A significant amount of bacteria in the urine, along with the presence of WBCs, can help diagnose an active infection in the urinary tract. Bacteria may also be seen in cases of kidney infection (pyelonephritis). The specific type of bacteria can be identified through urine culture for targeted antibiotic treatment. If bacteria are found in a urine sample without symptoms (asymptomatic bacteriuria), further evaluation may be necessary to determine if treatment is required.',
                },
              },
              UrineCultureSensitivity: {
                name: '2.C. Urine Culture & Sensitivity (If Infection Suspected)',
                BacterialGrowthIdentification: {
                  name: 'Bacterial Growth Identification',
                  value: '',
                  normal_range: 'No Growth / <10³ CFU/mL',
                  description: 'This test involves growing bacteria from a urine sample to identify the specific microorganism causing an infection. When a UTI is suspected, a urine culture is performed by placing the urine sample on a special growth medium in a laboratory, where bacteria can multiply. The bacterial growth is then identified based on its characteristics, such as shape, size, and reaction to specific chemicals. This process helps pinpoint the exact type of bacteria (such as Escherichia coli, Klebsiella, or Proteus) responsible for the infection. Identifying the pathogen is crucial for accurate diagnosis and treatment.',
                },
                AntibioticSensitivityTesting: {
                  name: 'Antibiotic Sensitivity Testing',
                  value: '',
                  normal_range: 'Based on Sensitivity Report',
                  description: 'Once the bacterial pathogen is identified, antibiotic sensitivity testing (also called antibiotic susceptibility testing) is performed to determine which antibiotics are effective at killing or inhibiting the growth of the bacteria. In this test, bacteria are exposed to different antibiotics in a controlled setting to see which ones prevent bacterial growth. The results help doctors choose the most appropriate antibiotic for treatment, ensuring that the infection is treated effectively while also reducing the risk of antibiotic resistance. The testing results are classified as sensitive (effective), resistant (ineffective), or intermediate (moderate effectiveness) for each antibiotic.',
                },
              },
              UrinePregnancyTest: {
                name: '2.D. Urine Pregnancy Test (UPT)',
                'HumanChorionicGonadotropin)': {
                  name: 'hCG (Human Chorionic Gonadotropin)',
                  value: '',
                  normal_range: 'Negative (<5 mIU/mL)',
                  description: 'The Urine Pregnancy Test (UPT) detects the presence of human chorionic gonadotropin (hCG) in a woman\'s urine. hCG is a hormone produced by the placenta shortly after the fertilized egg attaches to the uterine lining, which occurs during pregnancy. The UPT works by detecting this hormone at levels that rise significantly during early pregnancy. A positive test result indicates pregnancy, while a negative result suggests that hCG levels are not yet detectable, or the woman is not pregnant. It is typically used for home testing or as an initial screening method in healthcare settings.',
                },
              },
              '24HourUrineAnalysis': {
                name: '2.E. 24-Hour Urine Analysis (If Required)',
                CreatinineClearance: {
                  name: 'Creatinine Clearance',
                  value: '',
                  normal_range: '88-128 mL/min (M), 75-115 mL/min (F)',
                  description: 'Creatinine clearance is a measure of kidney function. It assesses how well the kidneys are removing creatinine from the blood. A 24-hour urine sample is collected, and the amount of creatinine in the urine is compared to its concentration in the blood. The test helps estimate the glomerular filtration rate (GFR), which reflects how efficiently the kidneys are filtering waste products. It is often used to detect early stages of kidney dysfunction or monitor the progression of kidney disease.',
                },
                TotalProtein: {
                  name: 'Total Protein',
                  value: '',
                  normal_range: '<150 mg/day',
                  description: 'The total protein test measures the total amount of protein in the urine over a 24-hour period. Normally, only trace amounts of protein are present in urine. Higher levels of protein, a condition known as proteinuria, can indicate kidney disease, glomerulonephritis, diabetes, or hypertension. Persistent proteinuria may suggest significant kidney damage and warrants further evaluation and monitoring.',
                },
                Microalbuminuria: {
                  name: 'Microalbuminuria',
                  value: '',
                  normal_range: '<30 mg/day',
                  description: 'Microalbuminuria refers to the presence of small amounts of albumin (a type of protein) in the urine, which is an early sign of kidney damage, particularly in people with conditions like diabetes or hypertension. This test is more sensitive than standard protein tests and can detect early kidney dysfunction before significant protein loss occurs. Early detection of microalbuminuria is important for preventing further kidney damage.',
                },
                Electrolytes: {
                  name: 'Electrolytes (Na, K, Cl)',
                  value: '',
                  normal_range: {
                    Sodium: '40-220 mEq/day',
                    Potassium: '25-125 mEq/day',
                    Chloride: '110-250 mEq/day',
                  },
                  description: 'The analysis of electrolytes in a 24-hour urine sample measures the levels of sodium (Na), potassium (K), and chloride (Cl) in the urine. These electrolytes are essential for maintaining fluid balance, nerve function, and muscle function in the body. Abnormal levels of electrolytes in the urine can indicate imbalances caused by conditions such as kidney disease, dehydration, hormonal disorders (like aldosterone imbalances), or metabolic issues. Monitoring electrolyte levels in the urine helps assess kidney function and overall body fluid balance.',
                },
              },
            },
            SpecializedTests: {
              name: 'Specialized Tests',
              SpecializedTests:
                {
                  name: '3.A Specialized Tests',
                  CReactiveProtein_CRP: {
                    name: 'C-Reactive Protein (CRP)',
                    value: '',
                    normal_range: '<10 mg/L',
                    description: 'CRP is a protein produced by the liver in response to inflammation. The CRP test measures the level of this protein in the blood, with higher levels indicating the presence of inflammation or infection in the body. It is commonly used to monitor conditions like rheumatoid arthritis, lupus, bacterial infections, or other inflammatory diseases. It can also help assess the risk of heart disease.',
                  },
                  ErythrocyteSedimentationRate: {
                    name: 'Erythrocyte Sedimentation Rate (ESR)',
                    value: '',
                    normal_range: '0-20 mm/hr (M), 0-30 mm/hr (F)',
                    description: 'ESR measures how quickly red blood cells settle at the bottom of a test tube in a blood sample. The faster they settle, the higher the ESR, which indicates inflammation in the body. ESR is a non-specific test used to monitor inflammatory conditions such as rheumatoid arthritis, lupus, or infections. It can also be used to track disease progression or response to treatment.',
                  },
                  VitaminD: {
                    name: 'Vitamin D (25-Hydroxy Vitamin D)',
                    value: '',
                    normal_range: '30-100 ng/mL',
                    description: 'The 25-hydroxy vitamin D test measures the level of vitamin D in the blood. Vitamin D is essential for bone health, immune function, and other physiological processes. Deficiency can lead to bone disorders like osteoporosis or rickets, while excess levels may cause toxicity. This test helps diagnose vitamin D deficiency or excess and is often used to evaluate bone health and immune function.',
                  },
                  VitaminB12: {
                    name: 'Vitamin B12',
                    value: '',
                    normal_range: '200-900 pg/mL',
                    description: 'Vitamin B12 is important for nerve function, red blood cell production, and DNA synthesis. A B12 deficiency can lead to anemia, neurological issues, and cognitive problems. The vitamin B12 test measures the amount of B12 in the blood and is often used to diagnose conditions like pernicious anemia, malabsorption, or other vitamin B12-related deficiencies.',
                  },
                  IronStudies: {
                    name: 'Iron Studies (Serum Iron, TIBC, Ferritin)',
                    value: '',
                    normal_range: {
                      'Serum Iron': '50-170 µg/dL',
                      TIBC: '250-450 µg/dL',
                      Ferritin: '20-250 ng/mL (M), 10-150 ng/mL (F)',
                    },
                    description: 'Iron studies include tests that measure various components related to iron in the blood. Serum Iron: Measures the amount of iron bound to transferrin in the blood. Total Iron-Binding Capacity (TIBC): Reflects the total amount of iron that transferrin (the protein that carries iron) can carry. Ferritin: Measures the stored form of iron in the body, indicating iron reserves. These tests are used to diagnose iron deficiency anemia, iron overload disorders (like hemochromatosis), or other conditions related to iron metabolism.',
                  },
                  HormonalAssays: {
                    name: 'Hormonal Assays (Testosterone, Estrogen, Progesterone, LH, FSH, etc.)',
                    value: '',
                    normal_range: {
                      Testosterone: '300-1000 ng/dL (M), 15-70 ng/dL (F)',
                      Estrogen: '15-350 pg/mL',
                      Progesterone: '0.1-25 ng/mL',
                      LH: '1-9 IU/L',
                      FSH: '1-12 IU/L',
                    },
                    description: 'Hormonal assays measure the levels of various hormones in the blood that regulate bodily functions, including the reproductive system. Some key tests include: Testosterone: Main male sex hormone; used to evaluate testosterone deficiencies or excess. Estrogen: Primary female sex hormone; important in menstrual function, pregnancy, and menopause. Progesterone: A hormone involved in regulating the menstrual cycle and maintaining pregnancy. Luteinizing Hormone (LH) and Follicle-Stimulating Hormone (FSH): Both play roles in regulating the menstrual cycle and fertility. These tests help diagnose hormonal imbalances, reproductive issues, and conditions such as polycystic ovary syndrome (PCOS) or menopause.',
                  },
                  CardiacMarkers: {
                    name: 'Cardiac Markers (Troponin, CK-MB)',
                    value: '',
                    normal_range: {
                      Troponin: '<0.04 ng/mL',
                      'CK-MB': '<5 ng/mL',
                    },
                    description: 'Cardiac markers are substances released into the bloodstream when the heart is damaged. They are used to diagnose and assess the severity of heart-related conditions like heart attacks. Troponin: A protein found in heart muscle cells that is released when the heart muscle is injured. It is one of the most specific markers for heart attack or damage. CK-MB (Creatine Kinase-Muscle/Brain): An enzyme found in heart and skeletal muscle. Elevated levels can indicate a heart attack or muscle damage. It is often used alongside troponin to evaluate heart damage. These tests are critical in diagnosing acute myocardial infarction (heart attacks) and other cardiac conditions.',
                  },
                },
            },
          },
        },
      ],
      viewLabResultsDialog: false,
      LabsHeaders: [
        { text: this.$t('laboratories'), value: 'name', sortable: false, class: 'color_header' },
        { text: 'Date', value: 'date', sortable: false, class: 'color_header' },
        { text: '', sortable: false, class: 'color_header' },
      ],
      loading: false,
      search: '',
    };
  },
  computed: {
    ...mapState({
      detailedUser: (state) => state.users.detailedUser,
    }),
    ...mapGetters({
      pageHeight: 'pageHeight',
    }),
  },
  methods: {
    openLabResultsDialog (item) {
      this.loading = true;
      this.dialog = true;
      this.labDetails = item;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      },
    getDataTableFooterProps,
  },
};
</script>
<style scoped>
.header-cell {
  text-align: left;
  padding-left: 2px;
  color: black;
}
.medical-staff {
  width: 10%;
}
.note-detail {
  width: 89%;
}
.created-detail-cell {
  width: 13%;
}
.actions-detail-cell {
  width: 1%;
}
</style>
